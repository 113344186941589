<template>
  <van-checkbox v-bind="attrs" :disabled="disabled">
    <slot></slot>
    <template #icon="props">
      <div
        v-if="props.disabled && !props.checked"
        class="h-5 w-5 flex items-center justify-center rounded-sm bg-[#777474]"
      ></div>
      <div v-else-if="props.checked" class="h-5 w-5 flex items-center justify-center rounded-sm bg-black">
        <NuxtIcon name="my-icon:checkbox" text="2.5"></NuxtIcon>
      </div>
      <div v-else class="box-border h-5 w-5 b-1 border-black rounded-sm border-solid"></div>
    </template>
  </van-checkbox>
</template>

<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()
</script>
